//Migrated
<template>
  <Fab
    v-if="agentCall"
    color="blue"
    icon="phone"
  >
    <template #header>
      <h4 class="mb-0">
        Caller ID
      </h4>
      <span
        v-if="agentCall.active_call"
        class="ml-12"
      >{{ duration }}</span>
      <button
        class="btn btn-link text-white"
        type="button"
        @click="refresh"
      >
        <fa :icon="icons.faRedo" />
      </button>
    </template>
    <template #body>
      <p>
        <strong>
          {{ agentCall.firstname }} {{ agentCall.lastname }}
        </strong>
        <br />{{ agentCall.socialsecuritynumber }}
      </p>
      <p>
        {{ agentCall.address }}<br />{{ agentCall.zip }} {{ agentCall.city }}
      </p>
      <p>
        {{ agentCall.telephone }}<br />{{ agentCall.email }}
      </p>
      <template v-if="agentCall.active_call">
        <p>
          <a
            class="btn btn-blue"
            :href="agentCall.bookings_url"
            data-i18n="agentCallBookingsUrl"
          >{{ $t('agentCallBookingsUrl') }}</a>
        </p>
        <p v-if="orderable">
          <button
            class="btn btn-light-blue"
            type="button"
            data-i18n="agentCallPopulateForm"
            @click="dispatchData"
          >
            {{ $t('agentCallPopulateForm') }}
          </button>
        </p>
      </template>
      <strong
        v-else
        class="text-red"
        data-i18n="agentCallNoActiveCallWarning"
      >{{ $t('agentCallNoActiveCallWarning') }}</strong>
    </template>
  </Fab>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import dayjs from 'dayjs'
import { faRedo } from '@fortawesome/pro-solid-svg-icons'

export default defineNuxtComponent({
  setup () {
    const { urls: localeURLs } = useLocale()

    return {
      localeURLs,
    }
  },

  data () {
    return {
      icons: {
        faRedo,
      },

      currentTime: dayjs().unix(),
    }
  },

  computed: {
    ...mapState(useRootStore, {
      agentCall: 'showAgentCalls',
    }),

    duration () {
      let time = this.currentTime - this.agentCall.time

      const minutes = Math.floor(time / 60)
      time -= minutes * 60

      return `${('0' + minutes).slice(-2)}:${('0' + time).slice(-2)}`
    },

    orderable () {
      return this.$route.path.match(new RegExp(`^(${this.localeURLs.book})|(${this.localeURLs.catalogue})`))
    },
  },

  mounted () {
    window.setInterval(() => {
      this.currentTime = dayjs().unix()
    }, 1000)
  },

  methods: {
    ...mapActions(useRootStore, ['fetchAgentCall']),

    refresh () {
      this.fetchAgentCall()
    },

    dispatchData () {
      const event = new CustomEvent('agentCall', {
        detail: this.agentCall,
      })

      window.dispatchEvent(event)
    },
  },
})
</script>
